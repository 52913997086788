<template>
    <div>
        <Accordion
            v-for="item in accordionsData"
            :key="item._uid"
            :is-open="item.isOpen"
            :class="{ 'is-hidden-toggler': hideAccordion(item) }"
            @toggle="handleToggle(item._uid)"
        >
            <template #background>
                <div class="dt-accordion-section-title">{{ item.Title }}</div>
            </template>

            <template #title>
                <span :class="{ 'font-semibold': item.desktop_show_rows > 0 }">
                    {{
                        item.isOpen
                            ? item.accordion_close_title
                                ? item.accordion_close_title
                                : item.Title
                            : item.accordion_open_title
                              ? item.accordion_open_title
                              : item.Title
                    }}
                </span>
            </template>
            <template #icon>
                <NuxtIcon name="ri:arrow-down-s-line" />
            </template>
            <template #body>
                <ul :class="`grid-column-${item.desktop_list_column_count || 1}`">
                    <li
                        v-for="(entity, index) in item.entities.slice(
                            item.desktop_show_rows ? item.desktop_show_rows * (item.desktop_list_column_count || 1) : 0
                        )"
                        :key="'entity' + index"
                        class="link-wrapper"
                    >
                        <template v-if="entity.content">
                            <nuxt-link
                                :to="getLinkByProductType(entity, item.ProductType)"
                                :target="openEntitiesInCurrentTab ? null : '_blank'"
                                external
                            >
                                {{
                                    getAirportUrlName(entity.content.fullname) ??
                                    entity.content.city ??
                                    entity.content.city_name
                                }}
                                <span v-if="item.AppendText">{{ item.AppendText }}</span>
                            </nuxt-link>
                        </template>
                    </li>
                    <template
                        v-for="link in item.Links?.slice(
                            item.desktop_show_rows ? item.desktop_show_rows * (item.desktop_list_column_count || 1) : 0
                        )"
                        :key="link._uid"
                    >
                        <li :class="{ active: link.is_active }">
                            <nuxt-link :to="getInternalLink(link.Link)" :target="link.Target" external
                                >{{ link.Text }}
                            </nuxt-link>
                        </li>
                    </template>
                </ul>
            </template>

            <template v-if="item.desktop_show_rows > 0" #preshown_body>
                <ul :class="`grid-column-${item.desktop_list_column_count || 1}`">
                    <li
                        v-for="(entity, index) in item.entities?.slice(
                            0,
                            item.desktop_show_rows * (item.desktop_list_column_count || 1)
                        )"
                        :key="'entity' + index"
                        class="link-wrapper"
                    >
                        <template v-if="entity.content">
                            <nuxt-link
                                :to="getLinkByProductType(entity, item.ProductType)"
                                :target="openEntitiesInCurrentTab ? null : '_blank'"
                                external
                            >
                                {{
                                    getAirportUrlName(entity.content.fullname) ??
                                    entity.content.city ??
                                    entity.content.city_name
                                }}
                                <span v-if="item.AppendText">{{ item.AppendText }}</span>
                            </nuxt-link>
                        </template>
                    </li>
                    <template
                        v-for="link in item.Links?.slice(
                            0,
                            item.desktop_show_rows * (item.desktop_list_column_count || 1)
                        )"
                        :key="link._uid"
                    >
                        <li :class="{ active: link.is_active }">
                            <nuxt-link :to="getInternalLink(link.Link)" :target="link.Target" external
                                >{{ link.Text }}
                            </nuxt-link>
                        </li>
                    </template>
                </ul>
            </template>
        </Accordion>
    </div>
</template>

<script setup lang="ts">
import type { ILinkSection } from '~ui/types';
import Accordion from '~ui/components/common/Accordion.vue';
import { getLinkByProductType } from '~ui/utils/helpers';

const { links, openEntitiesInCurrentTab } = defineProps<{ links: ILinkSection[]; openEntitiesInCurrentTab: boolean }>();

// store accordions state(open/closed) in separate reactive variable
const accordionsState = links.map((item: any) => ({ _uid: item._uid, isOpen: false }));
const accordions = ref(accordionsState);
const accordionsData = computed(() => {
    return links.map((linkObj: any) => ({
        ...linkObj,
        ...accordions.value.find((accordion: any) => linkObj._uid === accordion._uid),
    }));
});

const getAirportUrlName = (entityFullname: any) => entityFullname?.replace(/airport/gi, '');

function handleToggle(toggleId: any) {
    accordions.value = accordions.value.map((accordion: any) => {
        accordion.isOpen = toggleId === accordion._uid ? !accordion.isOpen : false;
        return accordion;
    });
}

const hideAccordion = (item) =>
    item.entities.length + item.Links.length < item.desktop_show_rows * item.desktop_list_column_count;
</script>
