<template>
    <div>
        <div v-for="item in links" :key="item._uid" :style="[getLinkStyle(item)]">
            <h3
                v-if="item?.Title"
                class="link-section-title"
                :style="`
                    font-weight: ${item?.title_font_weight ? item?.title_font_weight : 500};
                    ${!isMobileView ? `font-size: ${item?.title_font_size ? item?.title_font_size : 1.53125}rem` : ''};
                `"
            >
                {{ item?.Title }}
            </h3>
            <hr v-if="item?.title_horizontal_break_line" />
            <ul class="links">
                <li v-for="(entity, index) in item.entities" :key="'entity' + index" class="link-wrapper">
                    <template v-if="entity.content">
                        <nuxt-link
                            :to="getLinkByProductType(entity, item.ProductType)"
                            :target="openEntitiesInCurrentTab ? null : '_blank'"
                            external
                        >
                            {{
                                getAirportUrlName(entity.content.fullname) ??
                                entity.content.city ??
                                entity.content.city_name
                            }}
                            <span v-if="item.AppendText">{{ item.AppendText }}</span>
                        </nuxt-link>
                    </template>
                </li>
                <template v-for="linkItem in item.Links" :key="linkItem._uid">
                    <li
                        class="link-wrapper"
                        :class="{ active: linkItem.is_active, 'with-image': linkItem.Icon?.filename }"
                    >
                        <NuxtImg
                            v-if="linkItem.Icon?.filename"
                            :src="linkItem.Icon?.filename"
                            :title="linkItem.Icon.title"
                            :alt="linkItem.Icon.alt"
                            provider="storyblok"
                            format="webp"
                            loading="lazy"
                            class="icon"
                            densities="x1"
                        />
                        <nuxt-link
                            v-if="linkItem && !linkItem.ModalId"
                            :to="getInternalLink(linkItem.Link)"
                            :target="linkItem.Target"
                            external
                            >{{ linkItem.Text }}
                        </nuxt-link>
                        <a v-else href="" @click.prevent="modalStore.toggleModal(linkItem.ModalId, true)">{{
                            linkItem.Text
                        }}</a>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ILinkSection } from '~ui/types';
import { useModalStore } from '../../../../apps/_base/store/modal';
import { useIsMobileView } from '~ui/composables/isMobileView';
import { getLinkByProductType } from '~ui/utils/helpers';

const { isMobileView } = useIsMobileView('768px');

const { links, columnCount, openEntitiesInCurrentTab } = defineProps<{
    links: ILinkSection[];
    columnCount: string;
    openEntitiesInCurrentTab: boolean;
}>();

const getLinkStyle = (link: ILinkSection) => {
    return {
        '--column-count-mobile': link.mobile_list_column_count || columnCount || null,
        '--hide-on-mobile': link.hide_on_mobile ? 'none' : 'grid',
        '--column-count-desktop': columnCount || null,
    };
};
const modalStore = useModalStore();
const getAirportUrlName = (entityFullname: any) => entityFullname?.replace(/airport/gi, '');
</script>

<style scoped lang="postcss">
.links {
    @apply md:grid gap-x-[0.8rem] px-4 grid-cols-[repeat(var(--column-count-mobile),1fr)] lg:grid-cols-[repeat(var(--column-count-desktop),1fr)];
    @media screen and (max-width: 768px) {
        display: var(--hide-on-mobile);
    }
}

a {
    @apply text-[#18b2e1] pr-4;

    &:hover {
        @apply underline;
    }
}

h3.link-section-title {
    @apply text-gray-800 text-[1.53125rem] font-medium leading-[1.2] pl-4 my-4;
}

h3.section-title {
    @apply mb-4 px-4 text-lg font-medium leading-6 text-gray-800;
    @apply sm:text-3xl;
}

.link-wrapper {
    @apply pr-4 py-1 relative;

    &.with-image {
        @apply pl-8;
    }

    .icon {
        @apply absolute h-4 w-4 left-0 top-2.5;
    }
}

.link-wrapper.active a {
    @apply underline;
}
</style>
