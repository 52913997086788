<template>
    <div>
        <Accordion
            v-for="item in accordionsData"
            :key="item._uid"
            :is-open="item.isOpen"
            :class="{ 'has-background': item.mobile_background?.filename, '!max-md:hidden': item.hide_on_mobile }"
            @toggle="handleToggle(item._uid)"
        >
            <template v-if="item.mobile_background?.filename" #background>
                <div class="wrapper">
                    <NuxtImg
                        v-if="item.mobile_background?.filename"
                        :src="item.mobile_background.filename"
                        :title="item.mobile_background.title"
                        :alt="item.mobile_background.alt"
                        provider="storyblok"
                        format="webp"
                        loading="lazy"
                        style="object-fit: cover"
                        densities="x1"
                    />
                    <span>{{ item.Title }}</span>
                </div>
            </template>
            <template #title>
                <span :class="{ 'font-bold': item.mobile_show_rows > 0 }">
                    {{
                        item.isOpen
                            ? item.accordion_close_title
                                ? item.accordion_close_title
                                : item.Title
                            : item.accordion_open_title
                              ? item.accordion_open_title
                              : item.Title
                    }}
                </span>
            </template>
            <template #icon>
                <NuxtIcon name="ri:arrow-down-s-line" />
            </template>
            <template #body>
                <ul :class="`grid-column-${item.mobile_list_column_count || 1}`">
                    <li
                        v-for="(entity, index) in item.entities.slice(
                            item.mobile_show_rows ? item.mobile_show_rows * (item.mobile_list_column_count || 1) : 0
                        )"
                        :key="'entity' + index"
                        class="link-wrapper"
                    >
                        <template v-if="entity.content">
                            <nuxt-link
                                :to="getLinkByProductType(entity, item.ProductType)"
                                :target="openEntitiesInCurrentTab ? null : '_blank'"
                                external
                            >
                                {{
                                    getAirportUrlName(entity.content.fullname) ??
                                    entity.content.city ??
                                    entity.content.city_name
                                }}
                                <span v-if="item.AppendText">{{ item.AppendText }}</span>
                            </nuxt-link>
                        </template>
                    </li>
                    <template
                        v-for="link in item.Links?.slice(
                            item.mobile_show_rows ? item.mobile_show_rows * (item.mobile_list_column_count || 1) : 0
                        )"
                        :key="link._uid"
                    >
                        <li :class="{ active: link.is_active }">
                            <nuxt-link :to="getInternalLink(link.Link)" :target="link.Target" external
                                >{{ link.Text }}
                            </nuxt-link>
                        </li>
                    </template>
                </ul>
            </template>
            <template v-if="item.mobile_show_rows > 0" #preshown_body>
                <ul :class="`grid-column-${item.mobile_list_column_count || 1}`">
                    <li
                        v-for="(entity, index) in item.entities?.slice(
                            0,
                            item.mobile_show_rows * (item.mobile_list_column_count || 1)
                        )"
                        :key="'entity' + index"
                        class="link-wrapper"
                    >
                        <template v-if="entity.content">
                            <nuxt-link
                                :to="getLinkByProductType(entity, item.ProductType)"
                                :target="openEntitiesInCurrentTab ? null : '_blank'"
                                external
                            >
                                {{
                                    getAirportUrlName(entity.content.fullname) ??
                                    entity.content.city ??
                                    entity.content.city_name
                                }}
                                <span v-if="item.AppendText">{{ item.AppendText }}</span>
                            </nuxt-link>
                        </template>
                    </li>
                    <template
                        v-for="link in item.Links?.slice(
                            0,
                            item.mobile_show_rows * (item.mobile_list_column_count || 1)
                        )"
                        :key="link._uid"
                    >
                        <li :class="{ active: link.is_active }">
                            <nuxt-link :to="getInternalLink(link.Link)" :target="link.Target" external
                                >{{ link.Text }}
                            </nuxt-link>
                        </li>
                    </template>
                </ul>
            </template>
        </Accordion>
    </div>
</template>

<script setup lang="ts">
import type { ILinkSection } from '~ui/types';
import Accordion from '~ui/components/common/Accordion.vue';
import { getLinkByProductType } from '~ui/utils/helpers';

const { links, openEntitiesInCurrentTab } = defineProps<{ links: ILinkSection[]; openEntitiesInCurrentTab: boolean }>();

// store accordions state(open/closed) in separate reactive variable
const accordionsState = links.map((item: any) => ({ _uid: item._uid, isOpen: false }));
const accordions = ref(accordionsState);
const accordionsData = computed(() => {
    return links.map((linkObj: any) => ({
        ...linkObj,
        ...accordions.value.find((accordion: any) => linkObj._uid === accordion._uid),
    }));
});

const getAirportUrlName = (entityFullname: any) => entityFullname?.replace(/airport/gi, '');

function handleToggle(toggleId: any) {
    accordions.value = accordions.value.map((accordion: any) => {
        accordion.isOpen = toggleId === accordion._uid ? !accordion.isOpen : false;
        return accordion;
    });
}
</script>

<style scoped lang="postcss">
.wrapper {
    @apply max-w-[1200px] pb-[0.8rem] flex justify-start md:justify-center m-auto;
}

a {
    @apply text-[#18b2e1] pr-4;

    &:hover {
        @apply underline;
    }
}

.link-wrapper {
    @apply pr-4 py-1;
}

.link-wrapper.active a {
    @apply underline;
}

:deep(.accordion) {
    .accordion-header {
        @apply p-4 flex justify-between font-semibold text-[#1f303d] leading-tight cursor-pointer leading-[1.2];

        .accordion-title {
            @apply leading-[1.2];
        }
    }

    .accordion-body {
        @apply px-4;

        ul {
            @apply grid gap-x-4;

            &.grid-column-1 {
                @apply grid-cols-[repeat(1,1fr)];
            }

            &.grid-column-2 {
                @apply grid-cols-[repeat(2,1fr)];
            }

            &.grid-column-3 {
                @apply grid-cols-[repeat(3,1fr)];
            }

            &.grid-column-4 {
                @apply grid-cols-[repeat(4,1fr)];
            }
        }

        li {
            @apply py-2 leading-[21px] block overflow-hidden text-ellipsis whitespace-nowrap;

            a {
                @apply text-sm font-normal;
            }
        }
    }

    .accordion-icon {
        @apply transition-transform duration-[0.3s] ease-[ease];
    }

    &.active {
        .accordion-icon {
            @apply -rotate-180;
        }
    }
}
</style>
