<template>
    <section v-editable="blok" :style="componentStyles">
        <div class="container mx-auto">
            <h3 v-if="blok.Title" class="section-title">{{ blok.Title }}</h3>
            <p v-if="blok.description" class="section-description" v-html="blok.description"></p>
            <div class="wrapper">
                <!-- Tab -->
                <div v-if="blok.Type === 'tab'" class="tab-group-wrapper">
                    <TabGroup :blok="blok"></TabGroup>
                </div>

                <!-- Accordion -->
                <div
                    v-if="blok.Type === 'accordion' || blok.Type === 'desktop_accordion'"
                    class="accordion-wrapper"
                    :class="{ 'hide-on-desktop': blok.Type === 'accordion' }"
                >
                    <LinkListAccordion
                        :links="blok.Links"
                        :open-entities-in-current-tab="blok.open_entities_in_current_tab"
                    />
                </div>

                <!-- Desktop Accordion -->
                <div v-if="blok.Type === 'desktop_accordion'" class="desktop-accordion-wrapper">
                    <LinkListDesktopAccordion
                        :links="blok.Links"
                        :column-count="columnCount"
                        :open-entities-in-current-tab="blok.open_entities_in_current_tab"
                    />
                </div>

                <!-- List -->
                <div
                    v-if="blok.Type === 'list' || (blok.Type === 'accordion' && !isMobileView)"
                    class="main-link-wrapper"
                >
                    <LinkListList
                        :links="blok.Links"
                        :column-count="columnCount"
                        :open-entities-in-current-tab="blok.open_entities_in_current_tab"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import TabGroup from '~ui/components/common/TabGroup.vue';
import LinkListAccordion from './LinkListAccordion.vue';
import LinkListDesktopAccordion from './LinkListDesktopAccordion.vue';
import LinkListList from './List.vue';
import type { ILinkList } from '~ui/types';
import { useIsMobileView } from '~ui/composables/isMobileView';

const { blok } = defineProps<{ blok: ILinkList }>();
const { isMobileView } = useIsMobileView('768px');
const columnCount = ref(blok.ColumnCount);
const componentStyles = computed(() => {
    return {
        '--link-list-item-color': blok.LinkItemColor?.color || null,
        'background-color': !isMobileView.value && blok.desktopBgColor ? blok.desktopBgColor.color : null,
        color: blok.textColor?.color || null,
    };
});

watch(isMobileView, (isMobile) => {
    if (!isMobile) {
        columnCount.value = blok.ColumnCount;
    } else {
        columnCount.value = 1;
    }
});
</script>

<style scoped lang="postcss">
section {
    @apply py-8;
}

.wrapper {
    @apply max-w-[1200px] pb-[0.8rem] flex justify-start md:justify-center m-auto;
}

.main-link-wrapper {
    @apply block w-full;
}

a {
    @apply text-[#18b2e1] pr-4;

    &:hover {
        @apply underline;
    }
}

h3.section-title {
    @apply mb-4 px-4 text-lg font-medium leading-6 text-gray-800;
    @apply sm:text-3xl;
}

.accordion-wrapper {
    @apply w-full md:hidden;
}

.tab-group-wrapper {
    @apply w-full;
}
</style>
